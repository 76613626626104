import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { dockerEnvironment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StrapiService {

  apiBaseUrl = dockerEnvironment.apiUrlStrapi

  constructor(private http: HttpClient) { }

  getTermoAdesao(){
    return this.http.get(`${this.apiBaseUrl}/api/telas/7`)
  }

  getEducacaoFiscal(){
    return this.http.get(`${this.apiBaseUrl}/api/telas/5`)
  }

  getConhecePrograma(){
    return this.http.get(`${this.apiBaseUrl}/api/telas/1`)
  }


  getConheceProgramaMobile(){
    return this.http.get(`${this.apiBaseUrl}/api/telas/8`)
  }

  getEducacaoFiscalMobile(){
    return this.http.get(`${this.apiBaseUrl}/api/telas/9`)
  }

  getLegislacaoMobile(){
    return this.http.get(`${this.apiBaseUrl}/api/telas/10`)
  }


  getComoFUnciona(){
    return this.http.get(`${this.apiBaseUrl}/api/telas/2`)
  }

  getComoReceber(){
    return this.http.get(`${this.apiBaseUrl}/api/telas/3`)
  }

  getGestaoPrograma(){
    return this.http.get(`${this.apiBaseUrl}/api/telas/4`) 
  }


  getLegislacao(){
    return this.http.get(`${this.apiBaseUrl}/api/telas/6`)
  }

  getImageUrl(url: string){
    return this.http.get(`${this.apiBaseUrl}${url}`)
  }

  getNoticias(){
    return this.http.get(`${this.apiBaseUrl}/api/noticias?populate=*&pagination[limit]=999&sort[0]=updatedAt:desc`)
  }

  getNoticiasPerIndice(i: any){
    return this.http.get(`${this.apiBaseUrl}/api/noticias?populate=*&pagination[limit]=${i}&sort[0]=updatedAt:desc`)
  }

  getMaterialDivulgacao(i: any){
    return this.http.get(`${this.apiBaseUrl}/api/material-de-divulgacoes?populate=*&pagination[limit]=999&sort[0]=updatedAt:desc`)
  }

  getNoticia(id: any) {
    return this.http.get(`${this.apiBaseUrl}/api/noticias/${id}?populate=*`)
  }

  login(request: any) {
    return this.http.post<any>(`${this.apiBaseUrl}/admin/login`, request)
  }

  cadastrar(request: any) {
    if (localStorage.getItem('jwtToken')){
      const token = localStorage.getItem('jwtToken') || '';  // Define um valor padrão (string vazia) caso seja null
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + token.slice(1, -1),
        'Content-Type': 'application/json'
      });
      return this.http.post<any>(`${this.apiBaseUrl}/admin/users`, request, { headers })
    }
    return null
    
  }

  update(request: any){
     
    let idToken = request.id
    if (localStorage.getItem('jwtToken')){
      const token = localStorage.getItem('jwtToken') || '';  // Define um valor padrão (string vazia) caso seja null
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + token.slice(1, -1),
        'Content-Type': 'application/json'
      });
      delete request.id
      return this.http.put<any>(`${this.apiBaseUrl}/admin/users/${idToken}`, request, { headers })
    }
    return null
  }

  getLegislacaoList(){
  return this.http.get(`${this.apiBaseUrl}/api/legislacaos`)
  }
}
